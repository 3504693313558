import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { isEqual } from 'lodash';

import { Grid, Paper } from '@mui/material';

import { Button } from 'src/components/Button';
import { getDateFormat } from 'src/components/RHF/DateField/utils';
import { ActiveFilters } from 'src/features/ActiveFilters';
import { FieldMapper } from 'src/features/FieldMapper';
import { FieldSelector } from 'src/features/JobsTable/components/FieldSelector';
import {
  haveFiltersChanged,
  isFilterPresent,
} from 'src/features/JobsTable/components/Filters/utils';
import { useFieldSelectorFields } from 'src/features/JobsTable/utilities/hooks';
import { WaveIcon } from 'src/features/WaveIcon';
import { FieldTransformed, FieldType, FieldValue } from 'src/pages/Job/Job.service';
import { usePreferencePrefix } from 'src/utilities/hooks';
import { useLanguageCodePreference } from 'src/utilities/hooks/useLanguageCodePreference';
import { ActiveFilter, usePreference } from 'src/utilities/hooks/usePreference';

export type PropsOfFilters = {
  areActiveFiltersVisible: boolean;
  setAreActiveFiltersVisible: Dispatch<SetStateAction<boolean>>;
};

export function Filters({ areActiveFiltersVisible, setAreActiveFiltersVisible }: PropsOfFilters) {
  const { t } = useTranslation();
  const { jobType, preferencePrefix } = usePreferencePrefix();
  const activeFiltersPreference = usePreference(`${preferencePrefix}.search`, []);

  const [filterFields, setFilterFields] = useState<Record<string, FieldValue | string[]>>({});
  const [formFilters, setFormFilters] = useState<ActiveFilter[]>([]);
  const [isFieldSelectorOpen, setIsFieldSelectorOpen] = useState(false);
  const searchFiltersPreference = usePreference(`${preferencePrefix}.filterFields`, '');
  const languageCodePreference = useLanguageCodePreference();
  const { savedAvailableFields, savedSelectedFields } = useFieldSelectorFields({
    fieldPreference: searchFiltersPreference,
    isFieldSelectorOpen: !areActiveFiltersVisible,
    type: 'search',
  });

  const pagePreference = usePreference(`${preferencePrefix}.listPage`, '0');

  function addFilterField(alias: string, value: string | Date | Date[]) {
    setFilterFields(Object.assign({}, filterFields, { [alias]: value }));
  }

  function addNewFormFilter({ alias, label, textValue, value }: ActiveFilter) {
    if (!formFilters.length) {
      // previousFormFilters and ... needed for adding multiple new formFilters at once
      // separated by comma
      setFormFilters((previousFormFilters) => [
        ...previousFormFilters,
        { [alias]: value, label, textValue: textValue },
      ]);
    } else if (!isFilterPresent(formFilters, alias, value)) {
      setFormFilters((previousFormFilters) => [
        ...previousFormFilters,
        { [alias]: value, label, textValue: textValue },
      ]);
    }
  }

  function getFieldChangeFunction(type: FieldType) {
    switch (type) {
      case 'boolean':
        return handleChangeCheckbox;
      case 'date':
        return handleChangeDateField;
      case 'datetime':
        return handleChangeDateField;
      case 'pick':
        return handleChangeMultipleSelect;
      case 'tradio':
        return handleChangeRadioField;
      default:
        return handleChangeTextField;
    }
  }

  function handleApplyFieldSelector(selectedFields?: FieldTransformed[]) {
    searchFiltersPreference.set(selectedFields?.map(({ id }) => id).join() || '');
  }

  function handleBlur({
    target: { id: alias, name: label, value },
  }: {
    target: { id: string; name: string; value: string };
  }) {
    if (value === '') addFilterField(alias, '');
    else if (alias !== 'jobid') addNewFormFilter({ alias, label, textValue: value, value });
    else {
      const jobId = filterFields[alias];

      if (typeof jobId === 'string') {
        // A jobId will have quotes if it is multiple jobId's e.g., "123, 591, 4428"
        const hasQuotes = jobId.includes('"', 0) && jobId.includes('"', jobId.length - 1);

        if (hasQuotes) {
          const quotelessJobId = jobId.replace(new RegExp(/["']/g), '');

          addNewFormFilter({ alias, label, textValue: value, value: quotelessJobId });
        } else {
          const splitValue = value.split(',');

          splitValue.forEach((value) => {
            addNewFormFilter({ alias, label, textValue: value, value });
          });
        }
      }
    }
  }

  function handleChangeCheckbox({
    target: { id: alias, name: label },
  }: ChangeEvent<HTMLInputElement>) {
    const value = 'X';

    if (filterFields[alias]) {
      handleDeleteFilterField({ [alias]: filterFields[alias] as string });
    } else {
      addFilterField(alias, value);
    }

    if (formFilters.some((formFilter) => formFilter[alias])) {
      setFormFilters(formFilters.filter((formFilter) => Object.keys(formFilter)[0] !== alias));
    } else {
      setFormFilters([
        ...formFilters,
        {
          [alias]: value,
          label,
          textValue: value,
        },
      ]);
    }
  }

  function handleChangeDateField({
    calendarOverlay,
    target: { id: alias, name: label, value },
  }: {
    calendarOverlay: HTMLElement;
    target: {
      name: string;
      id: string;
      value: Date | Date[];
    };
  }) {
    // Do nothing unless date is selected by mouse click
    // If date range is selected
    if (value !== null) {
      // Two dates have been selected
      if (Array.isArray(value) && value[1]) {
        // If both dates are equal, set the second date to null
        if (value[0].toString() === value[1].toString()) {
          value[1] = null as unknown as Date;
        }

        handleFinishCalendar({ alias, calendarOverlay, label, value });
        addFilterField(alias, '');
      } else {
        addFilterField(alias, value);
        setFormFilters([
          ...formFilters,
          { [alias]: value.toString(), label, textValue: value.toString() },
        ]);
      }
    }
  }

  function handleChangeMultipleSelect({
    target: { arrayValue, id: alias, name: label, textValue },
  }: {
    target: {
      arrayValue: string[];
      id: string;
      name: string;
      textValue: string;
    };
  }) {
    // Filter out all formFilters for this field to start fresh
    const _formFilters = formFilters.filter((formFilter) => {
      const formFilterAlias = Object.keys(formFilter)[0];

      return alias !== formFilterAlias;
    });

    // Create the formFilters for this field
    const multipleSelectFormFilters = arrayValue.sort().map((value, index) => {
      return {
        [alias]: value,
        label,
        textValue: textValue[index],
      };
    });

    setFilterFields({ ...filterFields, [alias]: arrayValue });
    setFormFilters([..._formFilters, ...multipleSelectFormFilters]);
  }

  function handleChangeRadioField({
    target: { id: alias, labels, value },
  }: ChangeEvent<HTMLInputElement>) {
    if (!labels) return;

    const fieldLabel = (labels[0].parentElement?.previousSibling as HTMLElement)?.innerText;
    const valueLabel = labels[0].innerText;

    addFilterField(alias, value);
    setFormFilters([
      ...formFilters.filter(({ label }) => label !== fieldLabel),
      { [alias]: value, label: fieldLabel, textValue: valueLabel },
    ]);
  }

  function handleChangeTextField({
    target: { id: alias, value },
  }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    addFilterField(alias, value);
  }

  async function handleDeleteActiveFilter(deleteFilter: ActiveFilter) {
    handleDeleteFilterField(deleteFilter);
    handleDeleteFormFilter(deleteFilter);

    const updatedActiveFilters = (activeFiltersPreference.value as ActiveFilter[]).filter(
      (activeFilter) => !isEqual(activeFilter, deleteFilter),
    );

    activeFiltersPreference.set(updatedActiveFilters);
  }

  function handleDeleteAllActiveFilters() {
    activeFiltersPreference.set([]);

    setFilterFields({});
    setFormFilters([]);
  }

  function handleDeleteAllFormFilters() {
    setFilterFields({});
    setFormFilters([]);
  }

  function handleDeleteFilterField(deleteFilter: Record<string, string>) {
    const deleteFilterKey = Object.keys(deleteFilter)[0];
    const { [deleteFilterKey]: deleteFilterValue, ..._filterFields } = filterFields;

    // If the deleteFilter has an array of values,
    // delete the single value rather than the entire property
    if (Array.isArray(deleteFilterValue) && deleteFilterValue.length > 1) {
      const deleteValue = deleteFilter[deleteFilterKey];

      setFilterFields({
        [deleteFilterKey]: deleteFilterValue.filter((value) => value !== deleteValue),
        ..._filterFields,
      });
    } else {
      setFilterFields(_filterFields);
    }
  }

  function handleDeleteFormFilter(deleteFilter: ActiveFilter) {
    const deleteFilterKey = Object.keys(deleteFilter)[0];
    const uniqueDeleteFilter = deleteFilterKey + deleteFilter[deleteFilterKey];

    // Return the formFilters who's unique key value pair doesn't match
    // the key value pair of parameter "deleteFilter"
    const _formFilters = formFilters.filter((filter) => {
      const filterKey = Object.keys(filter)[0];
      const uniqueFormFilter = filterKey + filter[filterKey];

      return uniqueFormFilter !== uniqueDeleteFilter;
    });

    handleDeleteFilterField({
      [deleteFilterKey]: deleteFilter[deleteFilterKey],
    });
    setFormFilters(_formFilters);
  }

  function handleFinishCalendar({
    alias,
    calendarOverlay,
    label,
    value,
  }: {
    alias: string;
    calendarOverlay: HTMLElement;
    label: string;
    value: Date | Date[];
  }) {
    const selectedDate = value.toString().split(',');
    const firstDate = getDateFormat(new Date(selectedDate[0]), languageCodePreference.value);
    const secondDate = getDateFormat(new Date(selectedDate[1]), languageCodePreference.value);

    // Determine if one or two dates are selected
    const dateValue = selectedDate[1] ? `${firstDate} - ${secondDate}` : firstDate;

    addNewFormFilter({ alias, label, textValue: dateValue, value: dateValue });

    // After a date is selected hide the date picker
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    calendarOverlay.panel.style.display = 'none';
  }

  function handleOpenFieldSelector() {
    setIsFieldSelectorOpen(true);
  }

  function handleCloseFieldSelector() {
    setIsFieldSelectorOpen(false);
  }

  function handleSubmitForm() {
    activeFiltersPreference.set(formFilters);
    pagePreference.set('0');
  }

  useEffect(() => {
    if (activeFiltersPreference.value.length) {
      // When activeFiltersPreference changes copy activeFiltersPreference
      // so that filterFields display the correct value and formFilters always match

      setFormFilters([...(activeFiltersPreference.value as ActiveFilter[])]);

      if (savedSelectedFields?.length) {
        const filterFields = {} as Record<string, FieldValue | string[]>;

        (activeFiltersPreference.value as ActiveFilter[]).forEach((activeFilter) => {
          const [activeFilterAlias, activeFilterValue] = Object.entries(activeFilter)[0];

          const isActiveFilterADateField = savedSelectedFields.some(
            ({ alias, type }) => alias === activeFilterAlias && type === 'date',
          );

          if (!isActiveFilterADateField) {
            // Only add activeFilter to filterFields if it isn't a DateField
            // since a DateField cannot accept multiple values\

            if (filterFields[activeFilterAlias]) {
              // If the activeFilter already has a value for it in filterFields
              //  it must be a multipleSelect
              // and a multipleSelect can have multiple values in form of an array
              if (Array.isArray(filterFields[activeFilterAlias])) {
                filterFields[activeFilterAlias] = [
                  ...(filterFields[activeFilterAlias] as string[]),
                  activeFilterValue,
                ];
              } else {
                filterFields[activeFilterAlias] = [
                  filterFields[activeFilterAlias] as string,
                  activeFilterValue,
                ];
              }
            } else {
              filterFields[activeFilterAlias] = activeFilterValue;
            }
          }
        });

        setFilterFields(filterFields);
      }
    }
  }, [activeFiltersPreference.value, savedSelectedFields]);

  useEffect(() => {
    setAreActiveFiltersVisible(true);
  }, [activeFiltersPreference.value]);

  useEffect(() => {
    setAreActiveFiltersVisible(true);
    setFormFilters([]);
  }, [jobType]);

  return !areActiveFiltersVisible ? (
    <Paper elevation={0}>
      <Grid alignItems="center" className="p-10" container justifyContent="flex-end">
        <Grid item>
          <Button
            disabled={!savedAvailableFields?.length}
            onClick={handleOpenFieldSelector}
            startIcon={<WaveIcon code="visibility" />}
            variant="text"
          >
            <Trans i18nKey="select_filter_fields_button">Select Filter Fields</Trans>
          </Button>

          <Button
            disabled={
              !haveFiltersChanged(activeFiltersPreference.value as ActiveFilter[], formFilters)
            }
            onClick={handleSubmitForm}
            startIcon={<WaveIcon code="done" />}
            variant="text"
          >
            <Trans i18nKey="apply_filters_button">Apply Filters</Trans>
          </Button>
        </Grid>
      </Grid>

      {formFilters.length ? (
        <ActiveFilters
          activeFilters={formFilters}
          onDeleteActiveFilter={handleDeleteFormFilter}
          onDeleteAllActiveFilters={handleDeleteAllFormFilters}
        />
      ) : null}

      <Grid alignItems="center" container justifyContent="flex-start">
        {savedSelectedFields?.map(({ alias, description, fieldData, id, name, type }) => {
          const fieldValue = filterFields[alias]
            ? filterFields[alias]
            : type === 'ccomplete'
            ? null
            : '';

          return (
            <Grid className="p-10" item key={`filter-${id}`}>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore  */}
              <FieldMapper
                alias={alias}
                description={description}
                label={name}
                onBlur={handleBlur}
                options={fieldData}
                selectionMode="single"
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                transferChangeField={(e) => {
                  getFieldChangeFunction(type)(e);
                }}
                type={type}
                value={fieldValue}
              />
            </Grid>
          );
        })}
      </Grid>

      {isFieldSelectorOpen ? (
        <FieldSelector
          fieldPreference={searchFiltersPreference}
          isOpen={isFieldSelectorOpen}
          onApplyFieldSelector={handleApplyFieldSelector}
          onCloseFieldSelector={handleCloseFieldSelector}
          savedAvailableFields={savedAvailableFields}
          savedSelectedFields={savedSelectedFields}
          type={t('filter_button', 'Filter')}
        />
      ) : null}
    </Paper>
  ) : activeFiltersPreference.value?.length ? (
    <ActiveFilters
      activeFilters={activeFiltersPreference.value as ActiveFilter[]}
      onDeleteActiveFilter={handleDeleteActiveFilter}
      onDeleteAllActiveFilters={handleDeleteAllActiveFilters}
    />
  ) : null;
}
